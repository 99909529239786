import "core-js/modules/web.dom-collections.iterator.js";
import { Map as ImmutableMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import { getSettings, changeSetting } from 'soapbox/actions/settings';
import { getFeatures } from 'soapbox/utils/features';
import api, { getLinks } from '../api';
const CHATS_FETCH_REQUEST = 'CHATS_FETCH_REQUEST';
const CHATS_FETCH_SUCCESS = 'CHATS_FETCH_SUCCESS';
const CHATS_FETCH_FAIL = 'CHATS_FETCH_FAIL';
const CHATS_EXPAND_REQUEST = 'CHATS_EXPAND_REQUEST';
const CHATS_EXPAND_SUCCESS = 'CHATS_EXPAND_SUCCESS';
const CHATS_EXPAND_FAIL = 'CHATS_EXPAND_FAIL';
const CHAT_MESSAGES_FETCH_REQUEST = 'CHAT_MESSAGES_FETCH_REQUEST';
const CHAT_MESSAGES_FETCH_SUCCESS = 'CHAT_MESSAGES_FETCH_SUCCESS';
const CHAT_MESSAGES_FETCH_FAIL = 'CHAT_MESSAGES_FETCH_FAIL';
const CHAT_MESSAGE_SEND_REQUEST = 'CHAT_MESSAGE_SEND_REQUEST';
const CHAT_MESSAGE_SEND_SUCCESS = 'CHAT_MESSAGE_SEND_SUCCESS';
const CHAT_MESSAGE_SEND_FAIL = 'CHAT_MESSAGE_SEND_FAIL';
const CHAT_FETCH_REQUEST = 'CHAT_FETCH_REQUEST';
const CHAT_FETCH_SUCCESS = 'CHAT_FETCH_SUCCESS';
const CHAT_FETCH_FAIL = 'CHAT_FETCH_FAIL';
const CHAT_READ_REQUEST = 'CHAT_READ_REQUEST';
const CHAT_READ_SUCCESS = 'CHAT_READ_SUCCESS';
const CHAT_READ_FAIL = 'CHAT_READ_FAIL';
const CHAT_MESSAGE_DELETE_REQUEST = 'CHAT_MESSAGE_DELETE_REQUEST';
const CHAT_MESSAGE_DELETE_SUCCESS = 'CHAT_MESSAGE_DELETE_SUCCESS';
const CHAT_MESSAGE_DELETE_FAIL = 'CHAT_MESSAGE_DELETE_FAIL';

const fetchChatsV1 = () => (dispatch, getState) => api(getState).get('/api/v1/pleroma/chats').then(response => {
  dispatch({
    type: CHATS_FETCH_SUCCESS,
    chats: response.data
  });
}).catch(error => {
  dispatch({
    type: CHATS_FETCH_FAIL,
    error
  });
});

const fetchChatsV2 = () => (dispatch, getState) => api(getState).get('/api/v2/pleroma/chats').then(response => {
  let next = getLinks(response).refs.find(link => link.rel === 'next');

  if (!next && response.data.length) {
    next = {
      uri: "/api/v2/pleroma/chats?max_id=".concat(response.data[response.data.length - 1].id, "&offset=0")
    };
  }

  dispatch({
    type: CHATS_FETCH_SUCCESS,
    chats: response.data,
    next: next ? next.uri : null
  });
}).catch(error => {
  dispatch({
    type: CHATS_FETCH_FAIL,
    error
  });
});

const fetchChats = () => (dispatch, getState) => {
  const state = getState();
  const {
    instance
  } = state;
  const features = getFeatures(instance);
  dispatch({
    type: CHATS_FETCH_REQUEST
  });

  if (features.chatsV2) {
    return dispatch(fetchChatsV2());
  } else {
    return dispatch(fetchChatsV1());
  }
};

const expandChats = () => (dispatch, getState) => {
  const url = getState().chats.next;

  if (url === null) {
    return;
  }

  dispatch({
    type: CHATS_EXPAND_REQUEST
  });
  api(getState).get(url).then(response => {
    const next = getLinks(response).refs.find(link => link.rel === 'next');
    dispatch({
      type: CHATS_EXPAND_SUCCESS,
      chats: response.data,
      next: next ? next.uri : null
    });
  }).catch(error => {
    dispatch({
      type: CHATS_EXPAND_FAIL,
      error
    });
  });
};

const fetchChatMessages = function (chatId) {
  let maxId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return (dispatch, getState) => {
    dispatch({
      type: CHAT_MESSAGES_FETCH_REQUEST,
      chatId,
      maxId
    });
    return api(getState).get("/api/v1/pleroma/chats/".concat(chatId, "/messages"), {
      params: {
        max_id: maxId
      }
    }).then(_ref => {
      let {
        data
      } = _ref;
      dispatch({
        type: CHAT_MESSAGES_FETCH_SUCCESS,
        chatId,
        maxId,
        chatMessages: data
      });
    }).catch(error => {
      dispatch({
        type: CHAT_MESSAGES_FETCH_FAIL,
        chatId,
        maxId,
        error
      });
    });
  };
};

const sendChatMessage = (chatId, params) => (dispatch, getState) => {
  const uuid = "\u672B_".concat(Date.now(), "_").concat(uuidv4());
  const me = getState().me;
  dispatch({
    type: CHAT_MESSAGE_SEND_REQUEST,
    chatId,
    params,
    uuid,
    me
  });
  return api(getState).post("/api/v1/pleroma/chats/".concat(chatId, "/messages"), params).then(_ref2 => {
    let {
      data
    } = _ref2;
    dispatch({
      type: CHAT_MESSAGE_SEND_SUCCESS,
      chatId,
      chatMessage: data,
      uuid
    });
  }).catch(error => {
    dispatch({
      type: CHAT_MESSAGE_SEND_FAIL,
      chatId,
      error,
      uuid
    });
  });
};

const openChat = chatId => (dispatch, getState) => {
  const state = getState();
  const panes = getSettings(state).getIn(['chats', 'panes']);
  const idx = panes.findIndex(pane => pane.get('chat_id') === chatId);
  dispatch(markChatRead(chatId));

  if (idx > -1) {
    return dispatch(changeSetting(['chats', 'panes', idx, 'state'], 'open'));
  } else {
    const newPane = ImmutableMap({
      chat_id: chatId,
      state: 'open'
    });
    return dispatch(changeSetting(['chats', 'panes'], panes.push(newPane)));
  }
};

const closeChat = chatId => (dispatch, getState) => {
  const panes = getSettings(getState()).getIn(['chats', 'panes']);
  const idx = panes.findIndex(pane => pane.get('chat_id') === chatId);

  if (idx > -1) {
    return dispatch(changeSetting(['chats', 'panes'], panes.delete(idx)));
  } else {
    return false;
  }
};

const toggleChat = chatId => (dispatch, getState) => {
  const panes = getSettings(getState()).getIn(['chats', 'panes']);
  const [idx, pane] = panes.findEntry(pane => pane.get('chat_id') === chatId);

  if (idx > -1) {
    const state = pane.get('state') === 'minimized' ? 'open' : 'minimized';
    if (state === 'open') dispatch(markChatRead(chatId));
    return dispatch(changeSetting(['chats', 'panes', idx, 'state'], state));
  } else {
    return false;
  }
};

const toggleMainWindow = () => (dispatch, getState) => {
  const main = getSettings(getState()).getIn(['chats', 'mainWindow']);
  const state = main === 'minimized' ? 'open' : 'minimized';
  return dispatch(changeSetting(['chats', 'mainWindow'], state));
};

const fetchChat = chatId => (dispatch, getState) => {
  dispatch({
    type: CHAT_FETCH_REQUEST,
    chatId
  });
  return api(getState).get("/api/v1/pleroma/chats/".concat(chatId)).then(_ref3 => {
    let {
      data
    } = _ref3;
    dispatch({
      type: CHAT_FETCH_SUCCESS,
      chat: data
    });
  }).catch(error => {
    dispatch({
      type: CHAT_FETCH_FAIL,
      chatId,
      error
    });
  });
};

const startChat = accountId => (dispatch, getState) => {
  dispatch({
    type: CHAT_FETCH_REQUEST,
    accountId
  });
  return api(getState).post("/api/v1/pleroma/chats/by-account-id/".concat(accountId)).then(_ref4 => {
    let {
      data
    } = _ref4;
    dispatch({
      type: CHAT_FETCH_SUCCESS,
      chat: data
    });
    return data;
  }).catch(error => {
    dispatch({
      type: CHAT_FETCH_FAIL,
      accountId,
      error
    });
  });
};

const markChatRead = (chatId, lastReadId) => (dispatch, getState) => {
  const chat = getState().chats.items.get(chatId);
  if (!lastReadId) lastReadId = chat.last_message;
  if (chat.get('unread') < 1) return;
  if (!lastReadId) return;
  dispatch({
    type: CHAT_READ_REQUEST,
    chatId,
    lastReadId
  });
  api(getState).post("/api/v1/pleroma/chats/".concat(chatId, "/read"), {
    last_read_id: lastReadId
  }).then(_ref5 => {
    let {
      data
    } = _ref5;
    dispatch({
      type: CHAT_READ_SUCCESS,
      chat: data,
      lastReadId
    });
  }).catch(error => {
    dispatch({
      type: CHAT_READ_FAIL,
      chatId,
      error,
      lastReadId
    });
  });
};

const deleteChatMessage = (chatId, messageId) => (dispatch, getState) => {
  dispatch({
    type: CHAT_MESSAGE_DELETE_REQUEST,
    chatId,
    messageId
  });
  api(getState).delete("/api/v1/pleroma/chats/".concat(chatId, "/messages/").concat(messageId)).then(_ref6 => {
    let {
      data
    } = _ref6;
    dispatch({
      type: CHAT_MESSAGE_DELETE_SUCCESS,
      chatId,
      messageId,
      chatMessage: data
    });
  }).catch(error => {
    dispatch({
      type: CHAT_MESSAGE_DELETE_FAIL,
      chatId,
      messageId,
      error
    });
  });
};
/** Start a chat and launch it in the UI */


const launchChat = function (accountId, router) {
  let forceNavigate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  const isMobile = width => width <= 1190;

  return dispatch => {
    // TODO: make this faster
    return dispatch(startChat(accountId)).then(chat => {
      if (forceNavigate || isMobile(window.innerWidth)) {
        router.push("/chats/".concat(chat.id));
      } else {
        dispatch(openChat(chat.id));
      }
    });
  };
};

export { CHATS_FETCH_REQUEST, CHATS_FETCH_SUCCESS, CHATS_FETCH_FAIL, CHATS_EXPAND_REQUEST, CHATS_EXPAND_SUCCESS, CHATS_EXPAND_FAIL, CHAT_MESSAGES_FETCH_REQUEST, CHAT_MESSAGES_FETCH_SUCCESS, CHAT_MESSAGES_FETCH_FAIL, CHAT_MESSAGE_SEND_REQUEST, CHAT_MESSAGE_SEND_SUCCESS, CHAT_MESSAGE_SEND_FAIL, CHAT_FETCH_REQUEST, CHAT_FETCH_SUCCESS, CHAT_FETCH_FAIL, CHAT_READ_REQUEST, CHAT_READ_SUCCESS, CHAT_READ_FAIL, CHAT_MESSAGE_DELETE_REQUEST, CHAT_MESSAGE_DELETE_SUCCESS, CHAT_MESSAGE_DELETE_FAIL, fetchChatsV1, fetchChatsV2, fetchChats, expandChats, fetchChatMessages, sendChatMessage, openChat, closeChat, toggleChat, toggleMainWindow, fetchChat, startChat, markChatRead, deleteChatMessage, launchChat };