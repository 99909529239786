import "core-js/modules/es.array.includes.js";
import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';
import { REPORT_INIT, REPORT_SUBMIT_REQUEST, REPORT_SUBMIT_SUCCESS, REPORT_SUBMIT_FAIL, REPORT_CANCEL, REPORT_STATUS_TOGGLE, REPORT_COMMENT_CHANGE, REPORT_FORWARD_CHANGE, REPORT_BLOCK_CHANGE, REPORT_RULE_CHANGE } from '../actions/reports';
const NewReportRecord = ImmutableRecord({
  isSubmitting: false,
  account_id: null,
  status_ids: ImmutableSet(),
  comment: '',
  forward: false,
  block: false,
  rule_ids: ImmutableSet()
});
const ReducerRecord = ImmutableRecord({
  new: NewReportRecord()
});
export default function reports() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReducerRecord();
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case REPORT_INIT:
      return state.withMutations(map => {
        map.setIn(['new', 'isSubmitting'], false);
        map.setIn(['new', 'account_id'], action.account.id);

        if (state.new.account_id !== action.account.id) {
          var _action$status$reblog;

          map.setIn(['new', 'status_ids'], action.status ? ImmutableSet([((_action$status$reblog = action.status.reblog) === null || _action$status$reblog === void 0 ? void 0 : _action$status$reblog.id) || action.status.id]) : ImmutableSet());
          map.setIn(['new', 'comment'], '');
        } else if (action.status) {
          map.updateIn(['new', 'status_ids'], set => {
            var _action$status$reblog2;

            return set.add(((_action$status$reblog2 = action.status.reblog) === null || _action$status$reblog2 === void 0 ? void 0 : _action$status$reblog2.id) || action.status.id);
          });
        }
      });

    case REPORT_STATUS_TOGGLE:
      return state.updateIn(['new', 'status_ids'], set => {
        if (action.checked) {
          return set.add(action.statusId);
        }

        return set.remove(action.statusId);
      });

    case REPORT_COMMENT_CHANGE:
      return state.setIn(['new', 'comment'], action.comment);

    case REPORT_FORWARD_CHANGE:
      return state.setIn(['new', 'forward'], action.forward);

    case REPORT_BLOCK_CHANGE:
      return state.setIn(['new', 'block'], action.block);

    case REPORT_RULE_CHANGE:
      return state.updateIn(['new', 'rule_ids'], set => {
        if (set.includes(action.rule_id)) {
          return set.remove(action.rule_id);
        }

        return set.add(action.rule_id);
      });

    case REPORT_SUBMIT_REQUEST:
      return state.setIn(['new', 'isSubmitting'], true);

    case REPORT_SUBMIT_FAIL:
      return state.setIn(['new', 'isSubmitting'], false);

    case REPORT_CANCEL:
    case REPORT_SUBMIT_SUCCESS:
      return state.withMutations(map => {
        map.setIn(['new', 'account_id'], null);
        map.setIn(['new', 'status_ids'], ImmutableSet());
        map.setIn(['new', 'comment'], '');
        map.setIn(['new', 'isSubmitting'], false);
        map.setIn(['new', 'rule_ids'], ImmutableSet());
        map.setIn(['new', 'block'], false);
      });

    default:
      return state;
  }
}