import "core-js/modules/es.array.includes.js";
import MESSAGES from 'soapbox/locales/messages';
import { useSettings } from './useSettings';
/** Ensure the given locale exists in our codebase */

const validLocale = locale => Object.keys(MESSAGES).includes(locale);
/** Get valid locale from settings. */


const useLocale = function () {
  let fallback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
  const settings = useSettings();
  const locale = settings.get('locale');
  return validLocale(locale) ? locale : fallback;
};

export { useLocale };