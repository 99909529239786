import api from '../api';
import { importFetchedPoll } from './importer';
const POLL_VOTE_REQUEST = 'POLL_VOTE_REQUEST';
const POLL_VOTE_SUCCESS = 'POLL_VOTE_SUCCESS';
const POLL_VOTE_FAIL = 'POLL_VOTE_FAIL';
const POLL_FETCH_REQUEST = 'POLL_FETCH_REQUEST';
const POLL_FETCH_SUCCESS = 'POLL_FETCH_SUCCESS';
const POLL_FETCH_FAIL = 'POLL_FETCH_FAIL';

const vote = (pollId, choices) => (dispatch, getState) => {
  dispatch(voteRequest());
  api(getState).post("/api/v1/polls/".concat(pollId, "/votes"), {
    choices
  }).then(_ref => {
    let {
      data
    } = _ref;
    dispatch(importFetchedPoll(data));
    dispatch(voteSuccess(data));
  }).catch(err => dispatch(voteFail(err)));
};

const fetchPoll = pollId => (dispatch, getState) => {
  dispatch(fetchPollRequest());
  api(getState).get("/api/v1/polls/".concat(pollId)).then(_ref2 => {
    let {
      data
    } = _ref2;
    dispatch(importFetchedPoll(data));
    dispatch(fetchPollSuccess(data));
  }).catch(err => dispatch(fetchPollFail(err)));
};

const voteRequest = () => ({
  type: POLL_VOTE_REQUEST
});

const voteSuccess = poll => ({
  type: POLL_VOTE_SUCCESS,
  poll
});

const voteFail = error => ({
  type: POLL_VOTE_FAIL,
  error
});

const fetchPollRequest = () => ({
  type: POLL_FETCH_REQUEST
});

const fetchPollSuccess = poll => ({
  type: POLL_FETCH_SUCCESS,
  poll
});

const fetchPollFail = error => ({
  type: POLL_FETCH_FAIL,
  error
});

export { POLL_VOTE_REQUEST, POLL_VOTE_SUCCESS, POLL_VOTE_FAIL, POLL_FETCH_REQUEST, POLL_FETCH_SUCCESS, POLL_FETCH_FAIL, vote, fetchPoll, voteRequest, voteSuccess, voteFail, fetchPollRequest, fetchPollSuccess, fetchPollFail };