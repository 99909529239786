import api from '../api';
const MFA_FETCH_REQUEST = 'MFA_FETCH_REQUEST';
const MFA_FETCH_SUCCESS = 'MFA_FETCH_SUCCESS';
const MFA_FETCH_FAIL = 'MFA_FETCH_FAIL';
const MFA_BACKUP_CODES_FETCH_REQUEST = 'MFA_BACKUP_CODES_FETCH_REQUEST';
const MFA_BACKUP_CODES_FETCH_SUCCESS = 'MFA_BACKUP_CODES_FETCH_SUCCESS';
const MFA_BACKUP_CODES_FETCH_FAIL = 'MFA_BACKUP_CODES_FETCH_FAIL';
const MFA_SETUP_REQUEST = 'MFA_SETUP_REQUEST';
const MFA_SETUP_SUCCESS = 'MFA_SETUP_SUCCESS';
const MFA_SETUP_FAIL = 'MFA_SETUP_FAIL';
const MFA_CONFIRM_REQUEST = 'MFA_CONFIRM_REQUEST';
const MFA_CONFIRM_SUCCESS = 'MFA_CONFIRM_SUCCESS';
const MFA_CONFIRM_FAIL = 'MFA_CONFIRM_FAIL';
const MFA_DISABLE_REQUEST = 'MFA_DISABLE_REQUEST';
const MFA_DISABLE_SUCCESS = 'MFA_DISABLE_SUCCESS';
const MFA_DISABLE_FAIL = 'MFA_DISABLE_FAIL';

const fetchMfa = () => (dispatch, getState) => {
  dispatch({
    type: MFA_FETCH_REQUEST
  });
  return api(getState).get('/api/pleroma/accounts/mfa').then(_ref => {
    let {
      data
    } = _ref;
    dispatch({
      type: MFA_FETCH_SUCCESS,
      data
    });
  }).catch(() => {
    dispatch({
      type: MFA_FETCH_FAIL
    });
  });
};

const fetchBackupCodes = () => (dispatch, getState) => {
  dispatch({
    type: MFA_BACKUP_CODES_FETCH_REQUEST
  });
  return api(getState).get('/api/pleroma/accounts/mfa/backup_codes').then(_ref2 => {
    let {
      data
    } = _ref2;
    dispatch({
      type: MFA_BACKUP_CODES_FETCH_SUCCESS,
      data
    });
    return data;
  }).catch(() => {
    dispatch({
      type: MFA_BACKUP_CODES_FETCH_FAIL
    });
  });
};

const setupMfa = method => (dispatch, getState) => {
  dispatch({
    type: MFA_SETUP_REQUEST,
    method
  });
  return api(getState).get("/api/pleroma/accounts/mfa/setup/".concat(method)).then(_ref3 => {
    let {
      data
    } = _ref3;
    dispatch({
      type: MFA_SETUP_SUCCESS,
      data
    });
    return data;
  }).catch(error => {
    dispatch({
      type: MFA_SETUP_FAIL
    });
    throw error;
  });
};

const confirmMfa = (method, code, password) => (dispatch, getState) => {
  const params = {
    code,
    password
  };
  dispatch({
    type: MFA_CONFIRM_REQUEST,
    method,
    code
  });
  return api(getState).post("/api/pleroma/accounts/mfa/confirm/".concat(method), params).then(_ref4 => {
    let {
      data
    } = _ref4;
    dispatch({
      type: MFA_CONFIRM_SUCCESS,
      method,
      code
    });
    return data;
  }).catch(error => {
    dispatch({
      type: MFA_CONFIRM_FAIL,
      method,
      code,
      error,
      skipAlert: true
    });
    throw error;
  });
};

const disableMfa = (method, password) => (dispatch, getState) => {
  dispatch({
    type: MFA_DISABLE_REQUEST,
    method
  });
  return api(getState).delete("/api/pleroma/accounts/mfa/".concat(method), {
    data: {
      password
    }
  }).then(_ref5 => {
    let {
      data
    } = _ref5;
    dispatch({
      type: MFA_DISABLE_SUCCESS,
      method
    });
    return data;
  }).catch(error => {
    dispatch({
      type: MFA_DISABLE_FAIL,
      method,
      skipAlert: true
    });
    throw error;
  });
};

export { MFA_FETCH_REQUEST, MFA_FETCH_SUCCESS, MFA_FETCH_FAIL, MFA_BACKUP_CODES_FETCH_REQUEST, MFA_BACKUP_CODES_FETCH_SUCCESS, MFA_BACKUP_CODES_FETCH_FAIL, MFA_SETUP_REQUEST, MFA_SETUP_SUCCESS, MFA_SETUP_FAIL, MFA_CONFIRM_REQUEST, MFA_CONFIRM_SUCCESS, MFA_CONFIRM_FAIL, MFA_DISABLE_REQUEST, MFA_DISABLE_SUCCESS, MFA_DISABLE_FAIL, fetchMfa, fetchBackupCodes, setupMfa, confirmMfa, disableMfa };