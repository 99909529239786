import "core-js/modules/es.string.replace.js";

/** Returns `true` if the node contains only emojis, up to a limit */
export const onlyEmoji = function (node) {
  let limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  let ignoreMentions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (!node) return false;

  try {
    var _node$textContent;

    // Remove mentions before checking content
    if (ignoreMentions) {
      node = node.cloneNode(true);
      node.querySelectorAll('a.mention').forEach(m => {
        var _m$parentNode;

        return (_m$parentNode = m.parentNode) === null || _m$parentNode === void 0 ? void 0 : _m$parentNode.removeChild(m);
      });
    }

    if (((_node$textContent = node.textContent) === null || _node$textContent === void 0 ? void 0 : _node$textContent.replace(new RegExp(' ', 'g'), '')) !== '') return false;
    const emojis = Array.from(node.querySelectorAll('img.emojione'));
    if (emojis.length === 0) return false;
    if (emojis.length > limit) return false;
    const images = Array.from(node.querySelectorAll('img'));
    if (images.length > emojis.length) return false;
    return true;
  } catch (e) {
    // If anything in here crashes, skipping it is inconsequential.
    console.error(e);
    return false;
  }
};