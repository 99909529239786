import api, { getLinks } from '../api';
const SCHEDULED_STATUSES_FETCH_REQUEST = 'SCHEDULED_STATUSES_FETCH_REQUEST';
const SCHEDULED_STATUSES_FETCH_SUCCESS = 'SCHEDULED_STATUSES_FETCH_SUCCESS';
const SCHEDULED_STATUSES_FETCH_FAIL = 'SCHEDULED_STATUSES_FETCH_FAIL';
const SCHEDULED_STATUSES_EXPAND_REQUEST = 'SCHEDULED_STATUSES_EXPAND_REQUEST';
const SCHEDULED_STATUSES_EXPAND_SUCCESS = 'SCHEDULED_STATUSES_EXPAND_SUCCESS';
const SCHEDULED_STATUSES_EXPAND_FAIL = 'SCHEDULED_STATUSES_EXPAND_FAIL';
const SCHEDULED_STATUS_CANCEL_REQUEST = 'SCHEDULED_STATUS_CANCEL_REQUEST';
const SCHEDULED_STATUS_CANCEL_SUCCESS = 'SCHEDULED_STATUS_CANCEL_SUCCESS';
const SCHEDULED_STATUS_CANCEL_FAIL = 'SCHEDULED_STATUS_CANCEL_FAIL';

const fetchScheduledStatuses = () => (dispatch, getState) => {
  var _getState$status_list;

  if ((_getState$status_list = getState().status_lists.get('scheduled_statuses')) !== null && _getState$status_list !== void 0 && _getState$status_list.isLoading) {
    return;
  }

  dispatch(fetchScheduledStatusesRequest());
  api(getState).get('/api/v1/scheduled_statuses').then(response => {
    const next = getLinks(response).refs.find(link => link.rel === 'next');
    dispatch(fetchScheduledStatusesSuccess(response.data, next ? next.uri : null));
  }).catch(error => {
    dispatch(fetchScheduledStatusesFail(error));
  });
};

const cancelScheduledStatus = id => (dispatch, getState) => {
  dispatch({
    type: SCHEDULED_STATUS_CANCEL_REQUEST,
    id
  });
  api(getState).delete("/api/v1/scheduled_statuses/".concat(id)).then(_ref => {
    let {
      data
    } = _ref;
    dispatch({
      type: SCHEDULED_STATUS_CANCEL_SUCCESS,
      id,
      data
    });
  }).catch(error => {
    dispatch({
      type: SCHEDULED_STATUS_CANCEL_FAIL,
      id,
      error
    });
  });
};

const fetchScheduledStatusesRequest = () => ({
  type: SCHEDULED_STATUSES_FETCH_REQUEST
});

const fetchScheduledStatusesSuccess = (statuses, next) => ({
  type: SCHEDULED_STATUSES_FETCH_SUCCESS,
  statuses,
  next
});

const fetchScheduledStatusesFail = error => ({
  type: SCHEDULED_STATUSES_FETCH_FAIL,
  error
});

const expandScheduledStatuses = () => (dispatch, getState) => {
  var _getState$status_list2, _getState$status_list3;

  const url = ((_getState$status_list2 = getState().status_lists.get('scheduled_statuses')) === null || _getState$status_list2 === void 0 ? void 0 : _getState$status_list2.next) || null;

  if (url === null || (_getState$status_list3 = getState().status_lists.get('scheduled_statuses')) !== null && _getState$status_list3 !== void 0 && _getState$status_list3.isLoading) {
    return;
  }

  dispatch(expandScheduledStatusesRequest());
  api(getState).get(url).then(response => {
    const next = getLinks(response).refs.find(link => link.rel === 'next');
    dispatch(expandScheduledStatusesSuccess(response.data, next ? next.uri : null));
  }).catch(error => {
    dispatch(expandScheduledStatusesFail(error));
  });
};

const expandScheduledStatusesRequest = () => ({
  type: SCHEDULED_STATUSES_EXPAND_REQUEST
});

const expandScheduledStatusesSuccess = (statuses, next) => ({
  type: SCHEDULED_STATUSES_EXPAND_SUCCESS,
  statuses,
  next
});

const expandScheduledStatusesFail = error => ({
  type: SCHEDULED_STATUSES_EXPAND_FAIL,
  error
});

export { SCHEDULED_STATUSES_FETCH_REQUEST, SCHEDULED_STATUSES_FETCH_SUCCESS, SCHEDULED_STATUSES_FETCH_FAIL, SCHEDULED_STATUSES_EXPAND_REQUEST, SCHEDULED_STATUSES_EXPAND_SUCCESS, SCHEDULED_STATUSES_EXPAND_FAIL, SCHEDULED_STATUS_CANCEL_REQUEST, SCHEDULED_STATUS_CANCEL_SUCCESS, SCHEDULED_STATUS_CANCEL_FAIL, fetchScheduledStatuses, cancelScheduledStatus, fetchScheduledStatusesRequest, fetchScheduledStatusesSuccess, fetchScheduledStatusesFail, expandScheduledStatuses, expandScheduledStatusesRequest, expandScheduledStatusesSuccess, expandScheduledStatusesFail };