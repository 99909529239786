import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';
import { ALIASES_SUGGESTIONS_READY, ALIASES_SUGGESTIONS_CLEAR, ALIASES_SUGGESTIONS_CHANGE, ALIASES_FETCH_SUCCESS } from '../actions/aliases';
const ReducerRecord = ImmutableRecord({
  aliases: ImmutableRecord({
    items: ImmutableList(),
    loaded: false
  })(),
  suggestions: ImmutableRecord({
    items: ImmutableList(),
    value: '',
    loaded: false
  })()
});
export default function aliasesReducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReducerRecord();
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ALIASES_FETCH_SUCCESS:
      return state.setIn(['aliases', 'items'], action.value);

    case ALIASES_SUGGESTIONS_CHANGE:
      return state.setIn(['suggestions', 'value'], action.value).setIn(['suggestions', 'loaded'], false);

    case ALIASES_SUGGESTIONS_READY:
      return state.setIn(['suggestions', 'items'], ImmutableList(action.accounts.map(item => item.id))).setIn(['suggestions', 'loaded'], true);

    case ALIASES_SUGGESTIONS_CLEAR:
      return state.update('suggestions', suggestions => suggestions.withMutations(map => {
        map.set('items', ImmutableList());
        map.set('value', '');
        map.set('loaded', false);
      }));

    default:
      return state;
  }
}