import { Map as ImmutableMap, fromJS } from 'immutable';
import { ME_FETCH_SUCCESS } from 'soapbox/actions/me';
import { EMOJI_USE } from '../actions/emojis';
import { NOTIFICATIONS_FILTER_SET } from '../actions/notifications';
import { SEARCH_FILTER_SET } from '../actions/search';
import { SETTING_CHANGE, SETTING_SAVE, SETTINGS_UPDATE, FE_NAME } from '../actions/settings';

const updateFrequentEmojis = (state, emoji) => state.update('frequentlyUsedEmojis', ImmutableMap(), map => map.update(emoji.id, 0, count => count + 1)).set('saved', false);

const importSettings = (state, account) => {
  account = fromJS(account);
  const prefs = account.getIn(['pleroma', 'settings_store', FE_NAME], ImmutableMap());
  return state.merge(prefs);
}; // Default settings are in action/settings.js
//
// Settings should be accessed with `getSettings(getState()).getIn(...)`
// instead of directly from the state.


export default function settings() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ImmutableMap({
    saved: true
  });
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ME_FETCH_SUCCESS:
      return importSettings(state, action.me);

    case NOTIFICATIONS_FILTER_SET:
    case SEARCH_FILTER_SET:
    case SETTING_CHANGE:
      return state.setIn(action.path, action.value).set('saved', false);

    case EMOJI_USE:
      return updateFrequentEmojis(state, action.emoji);

    case SETTING_SAVE:
      return state.set('saved', true);

    case SETTINGS_UPDATE:
      return ImmutableMap(fromJS(action.settings));

    default:
      return state;
  }
}