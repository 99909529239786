import { Map as ImmutableMap } from 'immutable';
import { POLLS_IMPORT } from 'soapbox/actions/importer';
import { normalizeStatus } from 'soapbox/normalizers/status';

// HOTFIX: Convert the poll into a fake status to normalize it...
// TODO: get rid of POLLS_IMPORT and use STATUS_IMPORT here.
const normalizePoll = poll => {
  const status = {
    poll
  };
  return normalizeStatus(status).poll;
};

const importPolls = (state, polls) => {
  return state.withMutations(map => {
    return polls.forEach(poll => {
      const normalPoll = normalizePoll(poll);

      if (normalPoll && typeof normalPoll === 'object') {
        map.set(normalPoll.id, normalPoll);
      }
    });
  });
};

const initialState = ImmutableMap();
export default function polls() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case POLLS_IMPORT:
      return importPolls(state, action.polls);

    default:
      return state;
  }
}