import { getFeatures } from 'soapbox/utils/features';
import api from '../api';

const noOp = e => {};

const fetchMedia = mediaId => (dispatch, getState) => {
  return api(getState).get("/api/v1/media/".concat(mediaId));
};

const updateMedia = (mediaId, params) => (dispatch, getState) => {
  return api(getState).put("/api/v1/media/".concat(mediaId), params);
};

const uploadMediaV1 = function (data) {
  let onUploadProgress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : noOp;
  return (dispatch, getState) => api(getState).post('/api/v1/media', data, {
    onUploadProgress: onUploadProgress
  });
};

const uploadMediaV2 = function (data) {
  let onUploadProgress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : noOp;
  return (dispatch, getState) => api(getState).post('/api/v2/media', data, {
    onUploadProgress: onUploadProgress
  });
};

const uploadMedia = function (data) {
  let onUploadProgress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : noOp;
  return (dispatch, getState) => {
    const state = getState();
    const instance = state.instance;
    const features = getFeatures(instance);

    if (features.mediaV2) {
      return dispatch(uploadMediaV2(data, onUploadProgress));
    } else {
      return dispatch(uploadMediaV1(data, onUploadProgress));
    }
  };
};

export { fetchMedia, updateMedia, uploadMediaV1, uploadMediaV2, uploadMedia };