'use strict';

import { Record as ImmutableRecord } from 'immutable';
import { fetchInstance } from 'soapbox/actions/instance';
import { SW_UPDATING } from 'soapbox/actions/sw';
const ReducerRecord = ImmutableRecord({
  /** Whether /api/v1/instance 404'd (and we should display the external auth form). */
  instance_fetch_failed: false,

  /** Whether the ServiceWorker is currently updating (and we should display a loading screen). */
  swUpdating: false
});
export default function meta() {
  var _action$payload$respo;

  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReducerRecord();
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case fetchInstance.rejected.type:
      if (((_action$payload$respo = action.payload.response) === null || _action$payload$respo === void 0 ? void 0 : _action$payload$respo.status) === 404) {
        return state.set('instance_fetch_failed', true);
      }

      return state;

    case SW_UPDATING:
      return state.set('swUpdating', action.isUpdating);

    default:
      return state;
  }
}