import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';
import { MODAL_OPEN, MODAL_CLOSE } from '../actions/modals';
const ModalRecord = ImmutableRecord({
  modalType: '',
  modalProps: null
});
export default function modal() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ImmutableList();
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case MODAL_OPEN:
      return state.push(ModalRecord({
        modalType: action.modalType,
        modalProps: action.modalProps
      }));

    case MODAL_CLOSE:
      if (state.size === 0) {
        return state;
      }

      if (action.modalType === undefined) {
        return state.pop();
      }

      if (state.some(_ref => {
        let {
          modalType
        } = _ref;
        return action.modalType === modalType;
      })) {
        return state.slice(0, state.findLastIndex(_ref2 => {
          let {
            modalType
          } = _ref2;
          return action.modalType === modalType;
        }));
      }

      return state;

    default:
      return state;
  }
}