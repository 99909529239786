import { SIDEBAR_OPEN, SIDEBAR_CLOSE } from '../actions/sidebar';
const initialState = {
  sidebarOpen: false
};
export default function sidebar() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        sidebarOpen: true
      };

    case SIDEBAR_CLOSE:
      return {
        sidebarOpen: false
      };

    default:
      return state;
  }
}