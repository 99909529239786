import api from '../api';
import { importFetchedAccounts } from './importer';
export const FAMILIAR_FOLLOWERS_FETCH_REQUEST = 'FAMILIAR_FOLLOWERS_FETCH_REQUEST';
export const FAMILIAR_FOLLOWERS_FETCH_SUCCESS = 'FAMILIAR_FOLLOWERS_FETCH_SUCCESS';
export const FAMILIAR_FOLLOWERS_FETCH_FAIL = 'FAMILIAR_FOLLOWERS_FETCH_FAIL';
export const fetchAccountFamiliarFollowers = accountId => (dispatch, getState) => {
  dispatch({
    type: FAMILIAR_FOLLOWERS_FETCH_REQUEST,
    id: accountId
  });
  api(getState).get("/api/v1/accounts/familiar_followers?id=".concat(accountId)).then(_ref => {
    let {
      data
    } = _ref;
    const accounts = data.find(_ref2 => {
      let {
        id
      } = _ref2;
      return id === accountId;
    }).accounts;
    dispatch(importFetchedAccounts(accounts));
    dispatch({
      type: FAMILIAR_FOLLOWERS_FETCH_SUCCESS,
      id: accountId,
      accounts
    });
  }).catch(error => dispatch({
    type: FAMILIAR_FOLLOWERS_FETCH_FAIL,
    id: accountId,
    error
  }));
};