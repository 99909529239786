import "core-js/modules/es.array.includes.js";
import { AUTH_LOGGED_OUT, AUTH_ACCOUNT_REMEMBER_SUCCESS, VERIFY_CREDENTIALS_SUCCESS } from '../actions/auth';
import { ME_FETCH_SUCCESS, ME_FETCH_FAIL, ME_FETCH_SKIP, ME_PATCH_SUCCESS } from '../actions/me';
const initialState = null;

const handleForbidden = (state, error) => {
  var _error$response;

  if ([401, 403].includes((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status)) {
    return false;
  } else {
    return state;
  }
};

export default function me() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ME_FETCH_SUCCESS:
    case ME_PATCH_SUCCESS:
      return action.me.id;

    case VERIFY_CREDENTIALS_SUCCESS:
    case AUTH_ACCOUNT_REMEMBER_SUCCESS:
      return state || action.account.id;

    case ME_FETCH_SKIP:
    case AUTH_LOGGED_OUT:
      return false;

    case ME_FETCH_FAIL:
      return handleForbidden(state, action.error);

    default:
      return state;
  }
}