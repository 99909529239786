function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Record as ImmutableRecord, List as ImmutableList } from 'immutable';
import { ALERT_SHOW, ALERT_DISMISS, ALERT_CLEAR } from '../actions/alerts';
const AlertRecord = ImmutableRecord({
  key: 0,
  title: '',
  message: '',
  severity: 'info',
  actionLabel: '',
  actionLink: '',
  action: () => {},
  dismissAfter: 6000
});

/** Get next key based on last alert. */
const getNextKey = state => {
  const last = state.last();
  return last ? last.key + 1 : 0;
};
/** Import the alert. */


const importAlert = (state, alert) => {
  const key = getNextKey(state);
  const record = AlertRecord(_objectSpread(_objectSpread({}, alert), {}, {
    key
  }));
  return state.push(record);
};
/** Delete an alert by its key. */


const deleteAlert = (state, alert) => {
  return state.filterNot(item => item.key === alert.key);
};

export default function alerts() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ImmutableList();
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ALERT_SHOW:
      return importAlert(state, action);

    case ALERT_DISMISS:
      return deleteAlert(state, action.alert);

    case ALERT_CLEAR:
      return state.clear();

    default:
      return state;
  }
}