import api, { getLinks } from '../api';
import { importFetchedStatuses } from './importer';
const BOOKMARKED_STATUSES_FETCH_REQUEST = 'BOOKMARKED_STATUSES_FETCH_REQUEST';
const BOOKMARKED_STATUSES_FETCH_SUCCESS = 'BOOKMARKED_STATUSES_FETCH_SUCCESS';
const BOOKMARKED_STATUSES_FETCH_FAIL = 'BOOKMARKED_STATUSES_FETCH_FAIL';
const BOOKMARKED_STATUSES_EXPAND_REQUEST = 'BOOKMARKED_STATUSES_EXPAND_REQUEST';
const BOOKMARKED_STATUSES_EXPAND_SUCCESS = 'BOOKMARKED_STATUSES_EXPAND_SUCCESS';
const BOOKMARKED_STATUSES_EXPAND_FAIL = 'BOOKMARKED_STATUSES_EXPAND_FAIL';

const noOp = () => new Promise(f => f(undefined));

const fetchBookmarkedStatuses = () => (dispatch, getState) => {
  var _getState$status_list;

  if ((_getState$status_list = getState().status_lists.get('bookmarks')) !== null && _getState$status_list !== void 0 && _getState$status_list.isLoading) {
    return dispatch(noOp);
  }

  dispatch(fetchBookmarkedStatusesRequest());
  return api(getState).get('/api/v1/bookmarks').then(response => {
    const next = getLinks(response).refs.find(link => link.rel === 'next');
    dispatch(importFetchedStatuses(response.data));
    return dispatch(fetchBookmarkedStatusesSuccess(response.data, next ? next.uri : null));
  }).catch(error => {
    dispatch(fetchBookmarkedStatusesFail(error));
  });
};

const fetchBookmarkedStatusesRequest = () => ({
  type: BOOKMARKED_STATUSES_FETCH_REQUEST
});

const fetchBookmarkedStatusesSuccess = (statuses, next) => ({
  type: BOOKMARKED_STATUSES_FETCH_SUCCESS,
  statuses,
  next
});

const fetchBookmarkedStatusesFail = error => ({
  type: BOOKMARKED_STATUSES_FETCH_FAIL,
  error
});

const expandBookmarkedStatuses = () => (dispatch, getState) => {
  var _getState$status_list2, _getState$status_list3;

  const url = ((_getState$status_list2 = getState().status_lists.get('bookmarks')) === null || _getState$status_list2 === void 0 ? void 0 : _getState$status_list2.next) || null;

  if (url === null || (_getState$status_list3 = getState().status_lists.get('bookmarks')) !== null && _getState$status_list3 !== void 0 && _getState$status_list3.isLoading) {
    return dispatch(noOp);
  }

  dispatch(expandBookmarkedStatusesRequest());
  return api(getState).get(url).then(response => {
    const next = getLinks(response).refs.find(link => link.rel === 'next');
    dispatch(importFetchedStatuses(response.data));
    return dispatch(expandBookmarkedStatusesSuccess(response.data, next ? next.uri : null));
  }).catch(error => {
    dispatch(expandBookmarkedStatusesFail(error));
  });
};

const expandBookmarkedStatusesRequest = () => ({
  type: BOOKMARKED_STATUSES_EXPAND_REQUEST
});

const expandBookmarkedStatusesSuccess = (statuses, next) => ({
  type: BOOKMARKED_STATUSES_EXPAND_SUCCESS,
  statuses,
  next
});

const expandBookmarkedStatusesFail = error => ({
  type: BOOKMARKED_STATUSES_EXPAND_FAIL,
  error
});

export { BOOKMARKED_STATUSES_FETCH_REQUEST, BOOKMARKED_STATUSES_FETCH_SUCCESS, BOOKMARKED_STATUSES_FETCH_FAIL, BOOKMARKED_STATUSES_EXPAND_REQUEST, BOOKMARKED_STATUSES_EXPAND_SUCCESS, BOOKMARKED_STATUSES_EXPAND_FAIL, fetchBookmarkedStatuses, fetchBookmarkedStatusesRequest, fetchBookmarkedStatusesSuccess, fetchBookmarkedStatusesFail, expandBookmarkedStatuses, expandBookmarkedStatusesRequest, expandBookmarkedStatusesSuccess, expandBookmarkedStatusesFail };