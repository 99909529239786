import { isLoggedIn } from 'soapbox/utils/auth';
import { getFeatures } from 'soapbox/utils/features';
import api, { getLinks } from '../api';
import { fetchRelationships } from './accounts';
import { importFetchedAccounts } from './importer';
import { insertSuggestionsIntoTimeline } from './timelines';
const SUGGESTIONS_FETCH_REQUEST = 'SUGGESTIONS_FETCH_REQUEST';
const SUGGESTIONS_FETCH_SUCCESS = 'SUGGESTIONS_FETCH_SUCCESS';
const SUGGESTIONS_FETCH_FAIL = 'SUGGESTIONS_FETCH_FAIL';
const SUGGESTIONS_DISMISS = 'SUGGESTIONS_DISMISS';
const SUGGESTIONS_V2_FETCH_REQUEST = 'SUGGESTIONS_V2_FETCH_REQUEST';
const SUGGESTIONS_V2_FETCH_SUCCESS = 'SUGGESTIONS_V2_FETCH_SUCCESS';
const SUGGESTIONS_V2_FETCH_FAIL = 'SUGGESTIONS_V2_FETCH_FAIL';
const SUGGESTIONS_TRUTH_FETCH_REQUEST = 'SUGGESTIONS_TRUTH_FETCH_REQUEST';
const SUGGESTIONS_TRUTH_FETCH_SUCCESS = 'SUGGESTIONS_TRUTH_FETCH_SUCCESS';
const SUGGESTIONS_TRUTH_FETCH_FAIL = 'SUGGESTIONS_TRUTH_FETCH_FAIL';

const fetchSuggestionsV1 = function () {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (dispatch, getState) => {
    dispatch({
      type: SUGGESTIONS_FETCH_REQUEST,
      skipLoading: true
    });
    return api(getState).get('/api/v1/suggestions', {
      params
    }).then(_ref => {
      let {
        data: accounts
      } = _ref;
      dispatch(importFetchedAccounts(accounts));
      dispatch({
        type: SUGGESTIONS_FETCH_SUCCESS,
        accounts,
        skipLoading: true
      });
      return accounts;
    }).catch(error => {
      dispatch({
        type: SUGGESTIONS_FETCH_FAIL,
        error,
        skipLoading: true,
        skipAlert: true
      });
      throw error;
    });
  };
};

const fetchSuggestionsV2 = function () {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (dispatch, getState) => {
    const next = getState().suggestions.next;
    dispatch({
      type: SUGGESTIONS_V2_FETCH_REQUEST,
      skipLoading: true
    });
    return api(getState).get(next ? next : '/api/v2/suggestions', next ? {} : {
      params
    }).then(response => {
      var _getLinks$refs$find;

      const suggestions = response.data;
      const accounts = suggestions.map(_ref2 => {
        let {
          account
        } = _ref2;
        return account;
      });
      const next = (_getLinks$refs$find = getLinks(response).refs.find(link => link.rel === 'next')) === null || _getLinks$refs$find === void 0 ? void 0 : _getLinks$refs$find.uri;
      dispatch(importFetchedAccounts(accounts));
      dispatch({
        type: SUGGESTIONS_V2_FETCH_SUCCESS,
        suggestions,
        next,
        skipLoading: true
      });
      return suggestions;
    }).catch(error => {
      dispatch({
        type: SUGGESTIONS_V2_FETCH_FAIL,
        error,
        skipLoading: true,
        skipAlert: true
      });
      throw error;
    });
  };
};

const mapSuggestedProfileToAccount = suggestedProfile => ({
  id: suggestedProfile.account_id,
  avatar: suggestedProfile.account_avatar,
  avatar_static: suggestedProfile.account_avatar,
  acct: suggestedProfile.acct,
  display_name: suggestedProfile.display_name,
  note: suggestedProfile.note,
  verified: suggestedProfile.verified
});

const fetchTruthSuggestions = function () {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (dispatch, getState) => {
    const next = getState().suggestions.next;
    dispatch({
      type: SUGGESTIONS_V2_FETCH_REQUEST,
      skipLoading: true
    });
    return api(getState).get(next ? next : '/api/v1/truth/carousels/suggestions', next ? {} : {
      params
    }).then(response => {
      var _getLinks$refs$find2;

      const suggestedProfiles = response.data;
      const next = (_getLinks$refs$find2 = getLinks(response).refs.find(link => link.rel === 'next')) === null || _getLinks$refs$find2 === void 0 ? void 0 : _getLinks$refs$find2.uri;
      const accounts = suggestedProfiles.map(mapSuggestedProfileToAccount);
      dispatch(importFetchedAccounts(accounts, {
        should_refetch: true
      }));
      dispatch({
        type: SUGGESTIONS_TRUTH_FETCH_SUCCESS,
        suggestions: suggestedProfiles,
        next,
        skipLoading: true
      });
      return suggestedProfiles;
    }).catch(error => {
      dispatch({
        type: SUGGESTIONS_V2_FETCH_FAIL,
        error,
        skipLoading: true,
        skipAlert: true
      });
      throw error;
    });
  };
};

const fetchSuggestions = function () {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    limit: 50
  };
  return (dispatch, getState) => {
    const state = getState();
    const me = state.me;
    const instance = state.instance;
    const features = getFeatures(instance);
    if (!me) return null;

    if (features.truthSuggestions) {
      return dispatch(fetchTruthSuggestions(params)).then(suggestions => {
        const accountIds = suggestions.map(account => account.account_id);
        dispatch(fetchRelationships(accountIds));
      }).catch(() => {});
    } else if (features.suggestionsV2) {
      return dispatch(fetchSuggestionsV2(params)).then(suggestions => {
        const accountIds = suggestions.map(_ref3 => {
          let {
            account
          } = _ref3;
          return account.id;
        });
        dispatch(fetchRelationships(accountIds));
      }).catch(() => {});
    } else if (features.suggestions) {
      return dispatch(fetchSuggestionsV1(params)).then(accounts => {
        const accountIds = accounts.map(_ref4 => {
          let {
            id
          } = _ref4;
          return id;
        });
        dispatch(fetchRelationships(accountIds));
      }).catch(() => {});
    } else {
      // Do nothing
      return null;
    }
  };
};

const fetchSuggestionsForTimeline = () => (dispatch, _getState) => {
  var _dispatch;

  (_dispatch = dispatch(fetchSuggestions({
    limit: 20
  }))) === null || _dispatch === void 0 ? void 0 : _dispatch.then(() => dispatch(insertSuggestionsIntoTimeline()));
};

const dismissSuggestion = accountId => (dispatch, getState) => {
  if (!isLoggedIn(getState)) return;
  dispatch({
    type: SUGGESTIONS_DISMISS,
    id: accountId
  });
  api(getState).delete("/api/v1/suggestions/".concat(accountId));
};

export { SUGGESTIONS_FETCH_REQUEST, SUGGESTIONS_FETCH_SUCCESS, SUGGESTIONS_FETCH_FAIL, SUGGESTIONS_DISMISS, SUGGESTIONS_V2_FETCH_REQUEST, SUGGESTIONS_V2_FETCH_SUCCESS, SUGGESTIONS_V2_FETCH_FAIL, SUGGESTIONS_TRUTH_FETCH_REQUEST, SUGGESTIONS_TRUTH_FETCH_SUCCESS, SUGGESTIONS_TRUTH_FETCH_FAIL, fetchSuggestionsV1, fetchSuggestionsV2, fetchSuggestions, fetchSuggestionsForTimeline, dismissSuggestion };