import "core-js/modules/es.array.includes.js";

/**
 * globals: do things through the console.
 * This feature is for developers.
 */
import { changeSettingImmediate } from 'soapbox/actions/settings';

/** Add Soapbox globals to the window. */
export const createGlobals = store => {
  const Soapbox = {
    /** Become a developer with `Soapbox.isDeveloper()` */
    isDeveloper: function () {
      let bool = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (![true, false].includes(bool)) {
        throw "Invalid option ".concat(bool, ". Must be true or false.");
      }

      store.dispatch(changeSettingImmediate(['isDeveloper'], bool));
      return bool;
    }
  };
  window.Soapbox = Soapbox;
};