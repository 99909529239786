'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export default class Settings {
  constructor() {
    let keyBase = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    _defineProperty(this, "keyBase", null);

    this.keyBase = keyBase;
  }

  generateKey(id) {
    return this.keyBase ? [this.keyBase, "id".concat(id)].join('.') : id;
  }

  set(id, data) {
    const key = this.generateKey(id);

    try {
      const encodedData = JSON.stringify(data);
      localStorage.setItem(key, encodedData);
      return data;
    } catch (e) {
      return null;
    }
  }

  get(id) {
    const key = this.generateKey(id);

    try {
      const rawData = localStorage.getItem(key);
      return rawData ? JSON.parse(rawData) : null;
    } catch (e) {
      return null;
    }
  }

  remove(id) {
    const data = this.get(id);

    if (data) {
      const key = this.generateKey(id);

      try {
        localStorage.removeItem(key);
      } catch (e) {// Do nothing
      }
    }

    return data;
  }

}
/** Remember push notification settings. */

export const pushNotificationsSetting = new Settings('soapbox_push_notification_data');
/** Remember hashtag usage. */

export const tagHistory = new Settings('soapbox_tag_history');