import { showAlertForError } from '../actions/alerts';

/** Whether the action is considered a failure. */
const isFailType = type => type.endsWith('_FAIL');
/** Whether the action is a failure to fetch from browser storage. */


const isRememberFailType = type => type.endsWith('_REMEMBER_FAIL');
/** Whether the error contains an Axios response. */


const hasResponse = error => Boolean(error && error.response);
/** Don't show 401's. */


const authorized = error => {
  var _error$response;

  return (error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) !== 401;
};
/** Whether the error should be shown to the user. */


const shouldShowError = _ref => {
  let {
    type,
    skipAlert,
    error
  } = _ref;
  return !skipAlert && hasResponse(error) && authorized(error) && isFailType(type) && !isRememberFailType(type);
};
/** Middleware to display Redux errors to the user. */


export default function errorsMiddleware() {
  return _ref2 => {
    let {
      dispatch
    } = _ref2;
    return next => action => {
      if (shouldShowError(action)) {
        dispatch(showAlertForError(action.error));
      }

      return next(action);
    };
  };
}