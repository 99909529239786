function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { List as ImmutableList, Map as ImmutableMap, Record as ImmutableRecord } from 'immutable';
import { HISTORY_FETCH_REQUEST, HISTORY_FETCH_SUCCESS, HISTORY_FETCH_FAIL } from 'soapbox/actions/history';
import { normalizeStatusEdit } from 'soapbox/normalizers';
const HistoryRecord = ImmutableRecord({
  loading: false,
  items: ImmutableList()
});
const initialState = ImmutableMap();
export default function history() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case HISTORY_FETCH_REQUEST:
      return state.update(action.statusId, HistoryRecord(), history => history.withMutations(map => {
        map.set('loading', true);
        map.set('items', ImmutableList());
      }));

    case HISTORY_FETCH_SUCCESS:
      return state.update(action.statusId, HistoryRecord(), history => history.withMutations(map => {
        map.set('loading', false);
        map.set('items', ImmutableList(action.history.map((x, i) => _objectSpread(_objectSpread({}, x), {}, {
          account: x.account.id,
          original: i === 0
        })).reverse().map(normalizeStatusEdit)));
      }));

    case HISTORY_FETCH_FAIL:
      return state.update(action.statusId, HistoryRecord(), history => history.set('loading', false));

    default:
      return state;
  }
}