import "core-js/modules/web.dom-collections.iterator.js";
import { List as ImmutableList } from 'immutable';
import { isLoggedIn } from 'soapbox/utils/auth';
import api from '../api';
import { importFetchedAccounts, importFetchedStatus } from './importer';
import { favourite, unfavourite } from './interactions';
const EMOJI_REACT_REQUEST = 'EMOJI_REACT_REQUEST';
const EMOJI_REACT_SUCCESS = 'EMOJI_REACT_SUCCESS';
const EMOJI_REACT_FAIL = 'EMOJI_REACT_FAIL';
const UNEMOJI_REACT_REQUEST = 'UNEMOJI_REACT_REQUEST';
const UNEMOJI_REACT_SUCCESS = 'UNEMOJI_REACT_SUCCESS';
const UNEMOJI_REACT_FAIL = 'UNEMOJI_REACT_FAIL';
const EMOJI_REACTS_FETCH_REQUEST = 'EMOJI_REACTS_FETCH_REQUEST';
const EMOJI_REACTS_FETCH_SUCCESS = 'EMOJI_REACTS_FETCH_SUCCESS';
const EMOJI_REACTS_FETCH_FAIL = 'EMOJI_REACTS_FETCH_FAIL';

const noOp = () => () => new Promise(f => f(undefined));

const simpleEmojiReact = (status, emoji) => dispatch => {
  const emojiReacts = status.pleroma.get('emoji_reactions') || ImmutableList();
  if (emoji === '👍' && status.favourited) return dispatch(unfavourite(status));
  const undo = emojiReacts.filter(e => e.get('me') === true && e.get('name') === emoji).count() > 0;
  if (undo) return dispatch(unEmojiReact(status, emoji));
  return Promise.all([...emojiReacts.filter(emojiReact => emojiReact.get('me') === true).map(emojiReact => dispatch(unEmojiReact(status, emojiReact.get('name')))).toArray(), status.favourited && dispatch(unfavourite(status))]).then(() => {
    if (emoji === '👍') {
      dispatch(favourite(status));
    } else {
      dispatch(emojiReact(status, emoji));
    }
  }).catch(err => {
    console.error(err);
  });
};

const fetchEmojiReacts = (id, emoji) => (dispatch, getState) => {
  if (!isLoggedIn(getState)) return dispatch(noOp());
  dispatch(fetchEmojiReactsRequest(id, emoji));
  const url = emoji ? "/api/v1/pleroma/statuses/".concat(id, "/reactions/").concat(emoji) : "/api/v1/pleroma/statuses/".concat(id, "/reactions");
  return api(getState).get(url).then(response => {
    response.data.forEach(emojiReact => {
      dispatch(importFetchedAccounts(emojiReact.accounts));
    });
    dispatch(fetchEmojiReactsSuccess(id, response.data));
  }).catch(error => {
    dispatch(fetchEmojiReactsFail(id, error));
  });
};

const emojiReact = (status, emoji) => (dispatch, getState) => {
  if (!isLoggedIn(getState)) return dispatch(noOp());
  dispatch(emojiReactRequest(status, emoji));
  return api(getState).put("/api/v1/pleroma/statuses/".concat(status.get('id'), "/reactions/").concat(emoji)).then(function (response) {
    dispatch(importFetchedStatus(response.data));
    dispatch(emojiReactSuccess(status, emoji));
  }).catch(function (error) {
    dispatch(emojiReactFail(status, emoji, error));
  });
};

const unEmojiReact = (status, emoji) => (dispatch, getState) => {
  if (!isLoggedIn(getState)) return dispatch(noOp());
  dispatch(unEmojiReactRequest(status, emoji));
  return api(getState).delete("/api/v1/pleroma/statuses/".concat(status.get('id'), "/reactions/").concat(emoji)).then(response => {
    dispatch(importFetchedStatus(response.data));
    dispatch(unEmojiReactSuccess(status, emoji));
  }).catch(error => {
    dispatch(unEmojiReactFail(status, emoji, error));
  });
};

const fetchEmojiReactsRequest = (id, emoji) => ({
  type: EMOJI_REACTS_FETCH_REQUEST,
  id,
  emoji
});

const fetchEmojiReactsSuccess = (id, emojiReacts) => ({
  type: EMOJI_REACTS_FETCH_SUCCESS,
  id,
  emojiReacts
});

const fetchEmojiReactsFail = (id, error) => ({
  type: EMOJI_REACTS_FETCH_FAIL,
  id,
  error
});

const emojiReactRequest = (status, emoji) => ({
  type: EMOJI_REACT_REQUEST,
  status,
  emoji,
  skipLoading: true
});

const emojiReactSuccess = (status, emoji) => ({
  type: EMOJI_REACT_SUCCESS,
  status,
  emoji,
  skipLoading: true
});

const emojiReactFail = (status, emoji, error) => ({
  type: EMOJI_REACT_FAIL,
  status,
  emoji,
  error,
  skipLoading: true
});

const unEmojiReactRequest = (status, emoji) => ({
  type: UNEMOJI_REACT_REQUEST,
  status,
  emoji,
  skipLoading: true
});

const unEmojiReactSuccess = (status, emoji) => ({
  type: UNEMOJI_REACT_SUCCESS,
  status,
  emoji,
  skipLoading: true
});

const unEmojiReactFail = (status, emoji, error) => ({
  type: UNEMOJI_REACT_FAIL,
  status,
  emoji,
  error,
  skipLoading: true
});

export { EMOJI_REACT_REQUEST, EMOJI_REACT_SUCCESS, EMOJI_REACT_FAIL, UNEMOJI_REACT_REQUEST, UNEMOJI_REACT_SUCCESS, UNEMOJI_REACT_FAIL, EMOJI_REACTS_FETCH_REQUEST, EMOJI_REACTS_FETCH_SUCCESS, EMOJI_REACTS_FETCH_FAIL, simpleEmojiReact, fetchEmojiReacts, emojiReact, unEmojiReact, fetchEmojiReactsRequest, fetchEmojiReactsSuccess, fetchEmojiReactsFail, emojiReactRequest, emojiReactSuccess, emojiReactFail, unEmojiReactRequest, unEmojiReactSuccess, unEmojiReactFail };