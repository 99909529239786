import { getFeatures } from 'soapbox/utils/features';
import api from '../api';
import { importFetchedStatuses } from './importer';
const TRENDING_STATUSES_FETCH_REQUEST = 'TRENDING_STATUSES_FETCH_REQUEST';
const TRENDING_STATUSES_FETCH_SUCCESS = 'TRENDING_STATUSES_FETCH_SUCCESS';
const TRENDING_STATUSES_FETCH_FAIL = 'TRENDING_STATUSES_FETCH_FAIL';

const fetchTrendingStatuses = () => (dispatch, getState) => {
  const state = getState();
  const instance = state.instance;
  const features = getFeatures(instance);
  dispatch({
    type: TRENDING_STATUSES_FETCH_REQUEST
  });
  return api(getState).get(features.trendingTruths ? '/api/v1/truth/trending/truths' : '/api/v1/trends/statuses').then(_ref => {
    let {
      data: statuses
    } = _ref;
    dispatch(importFetchedStatuses(statuses));
    dispatch({
      type: TRENDING_STATUSES_FETCH_SUCCESS,
      statuses
    });
    return statuses;
  }).catch(error => {
    dispatch({
      type: TRENDING_STATUSES_FETCH_FAIL,
      error
    });
  });
};

export { TRENDING_STATUSES_FETCH_REQUEST, TRENDING_STATUSES_FETCH_SUCCESS, TRENDING_STATUSES_FETCH_FAIL, fetchTrendingStatuses };