'use strict';

import WebSocketClient from '@gamestdio/websocket';
import { getAccessToken } from 'soapbox/utils/auth';

const randomIntUpTo = max => Math.floor(Math.random() * Math.floor(max));

export function connectStream(path) {
  let pollingRefresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let callbacks = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => ({
    onConnect() {},

    onDisconnect() {},

    onReceive() {}

  });
  return (dispatch, getState) => {
    const streamingAPIBaseURL = getState().instance.urls.get('streaming_api');
    const accessToken = getAccessToken(getState());
    const {
      onConnect,
      onDisconnect,
      onReceive
    } = callbacks(dispatch, getState);
    let polling = null;

    const setupPolling = () => {
      if (pollingRefresh) {
        pollingRefresh(dispatch, () => {
          polling = setTimeout(() => setupPolling(), 20000 + randomIntUpTo(20000));
        });
      }
    };

    const clearPolling = () => {
      if (polling) {
        clearTimeout(polling);
        polling = null;
      }
    };

    let subscription; // If the WebSocket fails to be created, don't crash the whole page,
    // just proceed without a subscription.

    try {
      subscription = getStream(streamingAPIBaseURL, accessToken, path, {
        connected() {
          if (pollingRefresh) {
            clearPolling();
          }

          onConnect();
        },

        disconnected() {
          if (pollingRefresh) {
            polling = setTimeout(() => setupPolling(), randomIntUpTo(40000));
          }

          onDisconnect();
        },

        received(data) {
          onReceive(data);
        },

        reconnected() {
          if (pollingRefresh) {
            clearPolling();
            pollingRefresh(dispatch);
          }

          onConnect();
        }

      });
    } catch (e) {
      console.error(e);
    }

    const disconnect = () => {
      if (subscription) {
        subscription.close();
      }

      clearPolling();
    };

    return disconnect;
  };
}
export default function getStream(streamingAPIBaseURL, accessToken, stream, _ref) {
  let {
    connected,
    received,
    disconnected,
    reconnected
  } = _ref;
  const params = ["stream=".concat(stream)];
  const ws = new WebSocketClient("".concat(streamingAPIBaseURL, "/api/v1/streaming/?").concat(params.join('&')), accessToken);
  ws.onopen = connected;
  ws.onclose = disconnected;
  ws.onreconnect = reconnected;

  ws.onmessage = e => {
    if (!e.data) return;

    try {
      received(JSON.parse(e.data));
    } catch (error) {
      console.error(e);
      console.error("Could not parse the above streaming event.\n".concat(error));
    }
  };

  return ws;
}